html {
    font-size: 16px;
}

body {
    font-family: 'Circular', sans-serif;
    color: white;
    background-image: linear-gradient(165deg, #1db954, #121212 30% 30%);
    background-repeat: no-repeat;
    background-attachment: fixed;
    text-align: center;
}

button {
    cursor: pointer;
}

.section {
    display: grid;
    place-items: center;
    align-content: center;
    max-width: 50rem;
    margin: 0 auto;
    padding: 0 2rem;
}

.section a {
    color: white;
}