.track-list-container {
    display: grid;
    grid-template-columns: 1fr;
    gap: 0.5rem;
    margin-bottom: 1rem;
}

.track-list-item {
    display: grid;
    grid-template-columns: 1fr 1fr 2fr 2fr 2fr 1fr 1fr;
    align-items: center;
    text-align: center;
    gap: 0.5rem;
    padding: 0.5rem;
    background-color: #727272;
    border-radius: 0.5rem;
    box-shadow: 4px 4px 50px 0px #121212;
}
  
.track-list-item img {
    width: 5rem;
    height: 5rem;
    object-fit: cover;
}
  
.track-list-header {
    display: grid;
    grid-template-columns: 1fr 1fr 2fr 2fr 2fr 1fr 1fr;
    align-items: center;
    text-align: center;
    padding: 0.5rem;
    font-weight: bold;
}
  
@media screen and (max-width: 50rem) {
    .track-list-item {
        grid-template-columns: 1fr 1fr;
    }

    .track-list-item img {
        width: 100%;
        height: auto;
        max-width: 5rem;
        max-height: 5rem;
    }
}

@media screen and (max-width: 30rem) {
    .track-list-item {
        grid-template-columns: 1fr;
        grid-template-rows: auto auto auto auto;
    }

    .track-list-item img {
        grid-row: 1 / span 4;
        width: 100%;
        height: auto;
        max-width: 10rem;
        max-height: 10rem;
    }

    .track-list-header {
        display: none;
    }
}

.remove-button {
    color: white;
    text-decoration: none;
    padding: 0.5rem 0.5rem;
    border-radius: 0.5rem;
    background-color: #1db954;
    transition: background-color 0.3s ease;
    font-size: inherit;
    border: none;
}

.remove-button:hover {
    background-color: #1ed760;
}