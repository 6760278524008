.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #727272;
  padding: 0.5rem;
  border-radius: 0.5rem;
  box-shadow: 4px 4px 50px 0px #121212;
}

.navbar-logo a {
  color: white;
  font-size: 1.5rem;
  text-decoration: none;
  padding: 0.5rem;
  border-radius: 0.5rem;
}

.navbar-items {
  display: flex;
  gap: 1rem;
}

.navbar-items a {
  color: white;
  text-decoration: none;
  padding: 0.5rem 0.5rem;
  border-radius: 0.5rem;
  transition: background-color 0.3s ease;
}

.navbar-items a:hover {
  background-color: #1ed760;
}