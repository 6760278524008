.footer {
    padding: 2rem;
    text-align: center;
    margin-top: 3rem;
}

.footer-links {
    list-style-type: none;
    display: flex;
    justify-content: center;
    gap: 1rem;
    margin-bottom: 1rem;
}

.footer-links li {
    display: inline-block;
}

.footer-links a {
    text-decoration: none;
    color: white;
}

.footer-links a:hover {
    text-decoration: underline;
}

.footer-text {
    margin-top: 1rem;
    font-size: 1rem;
    color: #777;
}

.footer-line {
    height: 0.1rem;
    background-color: #fff;
    margin: 1rem 0;
}