.loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 3rem;
}

.loading-container p {
    margin-right: 1rem;
}

.playlist-container {
    margin-top : 1rem;
}

.playlist-buttons {
    display: flex;
    justify-content: center;
    gap: 1rem;
    margin-top: 1rem;
}

.playlist-buttons button {
    color: white;
    text-decoration: none;
    padding: 0.5rem 0.5rem;
    border-radius: 0.5rem;
    background-color: #1db954;
    transition: background-color 0.3s ease;
    font-size: inherit;
    border: none;
}

.playlist-buttons button:hover {
    background-color: #1ed760;
}

.playlist-duration {
    display: flex;
    justify-content: right;
    align-items: right;
}

.spotify-logo {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 3rem;
}