.contact-links {
    margin-top: 1rem;
}

.contact-links a {
    text-decoration: none;
    color: #333;
}

.contact-separator {
    margin: 0 0.5rem;
}